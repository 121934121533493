import React from "react"

import "./blockReveal.scss"

class BlockReveal extends React.Component {

	constructor(props) {
		super(props)
		this.state = {
			isVisible: false,
		};
	}

	componentDidMount() {
		const {autoPlayDelay} = this.props;
		if(autoPlayDelay) {
			setTimeout(()=>{
				this.setState({
					isVisible: true,
				});
			}, autoPlayDelay)
		}
	}

	componentWillReceiveProps(nextProps) {
		const {autoPlayDelay, delay} = this.props;
		if(!autoPlayDelay) {
			setTimeout(() => {
				this.setState({
					isVisible: nextProps.isVisible,
				});
			}, delay)
		}
	}

	shouldComponentUpdate(nextProps) {
		if(!nextProps.isVisible && nextProps.isVisible !== undefined) return false;
		return true;
	}

	render() {
		const { isVisible } = this.state;
		const { tag, className, children } = this.props;
		const Tag = tag || 'div';

		return (
			<Tag className={`blockReveal ${isVisible && "blockReveal--visible"} ${className}`}>
				{children}
			</Tag>
		)
	}
}

export default BlockReveal