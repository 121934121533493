import React from "react"

import Layout from "../components/layout"
import About from "../components/About"
import Content from "../components/Content"
import SEO from "../components/seo"

class IndexPage extends React.Component {

  state = {
    active: false,
    ready: false,
    anim: false,
  }

  componentDidMount() {
    window.scrollTo(0, 0);
    setTimeout(() => {
      this.setState({
        active: true,
      });

      setTimeout(() => {
        this.setState({
          anim: true,
        });
        setTimeout(() => {
          this.setState({
            ready: true,
          });
        }, 1000)
      }, 500)

    }, 500);
  }

  render() {
    const { active, top, ready, anim } = this.state;
    return (
      <Layout active={active} top={top} ready={ready}>
        <SEO title="Home" />
        {anim && <>
          <About />
          <Content />
        </>}
      </Layout>
    )
  }
}
export default IndexPage
