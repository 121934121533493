import React from "react"

const MOBILE_WIDTH_TRIGGER = 900;

class Video extends React.Component {

	constructor(props) {
		super(props)
		this.state = {
            isMobile: true,
        };
        
        this.onResize = this.onResize.bind(this);
    }
    
    componentDidMount() {
        window.addEventListener("resize", this.onResize);
        this.onResize();
    }

    componentWillUnmount() {
        window.removeEventListener("resize", this.onResize);
    }

    onResize() {
        if(window.innerWidth < MOBILE_WIDTH_TRIGGER && !this.state.isMobile) {
            this.setState({
                isMobile: true,
            });
            // this.playerRef.src = this.props.srcs.mobile;
        } else if(window.innerWidth >= MOBILE_WIDTH_TRIGGER && this.state.isMobile) {
            this.setState({
                isMobile: false,
            });
            this.playerRef.src = this.props.srcs.desktop;
        }
    }

	render() {
		const { srcs, className } = this.props;

		return (
            <video autoPlay muted playsInline loop 
            ref={r => this.playerRef = r}
                style={{
                    width: "100%",
                }}
                className={className}
            >
                <source src={srcs.mobile} type="video/mp4"/>
            </video>
		)
	}
}

export default Video