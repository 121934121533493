import React from "react"
import Arrow from "../modules/Arrow"
import {scrollroot} from "../utils/index"
import "./footer.scss"

class Footer extends React.Component {

	constructor(props) {
		super(props);
		this.state = {
			active: false,
		}
	}

	componentDidMount() {
		this.scrollroot = scrollroot();
		this.onScroll = this.onScroll.bind(this);
		this.onResize = this.onResize.bind(this);
		this.onScrollTopClick = this.onScrollTopClick.bind(this);
		
		window.addEventListener("scroll", this.onScroll);
		window.addEventListener("resize", this.onResize);

		this.onResize();
	}

	componentWillUnmount() {
		window.removeEventListener("scroll", this.onScroll);
		window.removeEventListener("resize", this.onResize);
	}

	onScroll() {
		if(this.scrollroot.scrollTop > this.trigger && !this.state.active) {
			this.setState({
				active: true,
			})
		} else if(this.scrollroot.scrollTop < this.trigger && this.state.active) {
			this.setState({
				active: false,
			})
		}
	}

	onResize() {
		this.trigger = window.innerHeight * 2.5;
		this.onScroll();
	}

	onScrollTopClick() {
		document.documentElement.style.opacity = 0;
		this.onScroll();
		setTimeout(() => {
			this.scrollroot.scrollTop = 0;
			setTimeout(() => {
				document.documentElement.style.opacity = 1;
			}, 300);
		}, 300);
}	

	render() {
		const {active} = this.state;
		return (
			<div className={`footer`}>
				<div className={`scroll-up__wrapper ${active?' scroll-up__wrapper--active':''}`}
					onClick={this.onScrollTopClick}
					onKeyDown={()=>{}}
					role="button"
					tabIndex={0}
				>
					<div className={`scroll-up`}>
						<Arrow />
					</div>
				</div>
			</div>
		)
	}
}

export default Footer;