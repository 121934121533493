import React from "react"
import { StaticQuery, graphql } from "gatsby"
import Img from "gatsby-image"
import TextReveal from "../modules/TextReveal"
import BlockReveal from "../modules/BlockReveal"
import Video from "../modules/Video"
import InView from "react-intersection-observer"

import "./content.scss"

const Content = () => (
	<div className="content">
		<StaticQuery 
			query={
			graphql`
				query ProjectsQuery {
					allProjectsJson {
						edges {
							node {
								id
								title
								client
								role
								link {
									label
									url
								}
								year
								color
								mainAsset {
									url
									urlMobile
									src {
										childImageSharp {
											fluid(maxWidth: 2000, quality: 100) {
												...GatsbyImageSharpFluid_noBase64
											}
										}
									}
									type
									bgColorMain
								}
								description
								credit
								assets {
									image {
										name
										bgColor
										type
										url
										urlMobile
										src {
											childImageSharp {
												fluid(maxWidth: 2000, quality: 100) {
													...GatsbyImageSharpFluid_noBase64
												}
											}
										}
									}
									padding
									type
								}
							}
						}
					}
				}
			`
			}

			render={ data => (
				<Projects {...data}/>
			)}
		>
		</StaticQuery>
	</div>
)

class Projects extends React.Component {

	constructor() {
		super();

		this.state = {
			display: false,
			scrolling: false,
		}
		this.isScrolling = false;
		// this.onScroll = this.onScroll.bind(this);
	}
	
	
	componentDidMount() {
		// window.addEventListener("scroll", this.onScroll)

		setTimeout(() => {
			this.setState({
				display: true,
			})
		}, 100)
	}

	// onScroll() {

	// 	if(!this.state.scrolling) {
	// 		this.setState({
	// 			scrolling: true,
	// 		});
	// 	}

	// 	window.clearTimeout( this.isScrolling );
	// 	this.isScrolling = setTimeout(() => {
	// 		console.log( 'Scrolling has stopped.' );
	// 		this.setState({
	// 			scrolling: false,
	// 		});
	// 	}, 66);
	// }

	render() {
		const { display, scrolling } = this.state;
		const data = this.props.allProjectsJson.edges.map(e => {
			return e.node;
		})
		return (
			<div className={`container ${display?'container--display':''} ${scrolling?'scrolling':''}`}>
				{data && data.map((project, id) => {
					return <Project key={id} {...project} id={id+1}/>
				})}
			</div>
		)
	}
}

class Project extends React.Component {
	render() {
		const { title, client, role, link, color, year, assets, mainAsset, description, credit } = this.props;
		return (
			<div
				className={`section`}
				data-year={year}
				data-client={client}
				data-color={"#fff"}
				data-bgcolor={color}
			>
				<div
					style={{
						backgroundColor: color,
					}}
				>
					<div className="wrapper">
						<div className="project">
							<InView triggerOnce={true}>
								{({ inView, ref }) => (
									<div
										className={`
											asset-wrapper
											${inView?' asset-wrapper--inView':''}
											${mainAsset.bgColorMain ? ' asset-wrapper--padding':''}
										`}
										style={{
											backgroundColor: mainAsset.bgColorMain
										}}
										ref={ref}
									>
										{mainAsset.type !== "video" ?
											<Img 
												fluid={mainAsset.src.childImageSharp.fluid} alt={client}
												fadeIn={true}
												durationFadeIn={1000}
												loading={"eager"}
											/>
											:
											<Video srcs={{
												desktop:mainAsset.url,
												mobile:mainAsset.urlMobile
											}} className="" />
										}
									</div>
								)}
							</InView>
							<div className="project__desc-wrapper">
								
								<InView triggerOnce={true}>
									{({ inView, ref }) => (
										<>
											<div className="project__desc-title" ref={ref}>
												<TextReveal tag={"h2"} isVisible={inView}>
													{title}
												</TextReveal>
												<TextReveal tag={"p"} isVisible={inView} delay="300">
													<a className="hover-link hover-link--small" href={link.url} target="_blank" rel="noopener noreferrer">{link.label}</a>
												</TextReveal>
											</div>
											<BlockReveal tag={"p"} className="project__desc" isVisible={inView} delay="400">
												{description}
											</BlockReveal>
											{/* <p className="project__desc">{description}</p> */}
											<div className="project__info">
												<div className="project__info__item">
													<TextReveal tag={"p"} className="project__info__item__label" isVisible={inView} delay="800">
														agency
													</TextReveal>
													<TextReveal tag={"p"} className="project__info__item__copy" isVisible={inView} delay="850">
														{client}
													</TextReveal>
												</div>
												<div className="project__info__item">
													<TextReveal tag={"p"} className="project__info__item__label" isVisible={inView} delay="900">
														year
													</TextReveal>
													<TextReveal tag={"p"} className="project__info__item__copy" isVisible={inView} delay="950">
														{year}
													</TextReveal>
												</div>
												<div className="project__info__item">
													<TextReveal tag={"p"} className="project__info__item__label" isVisible={inView} delay="1000">
														role
													</TextReveal>
													<TextReveal tag={"p"} className="project__info__item__copy" isVisible={inView} delay="1050">
														{role}
													</TextReveal>
												</div>
											</div>
										</>
									)}
								</InView>
							</div>
						</div>
					</div>
				</div>
				<div className="wrapper section" 
					data-year={year}
					data-client={client}
					data-color={"#fff"}
					data-bgcolor={color}
				>
					<div className="project">
					{assets && assets.map((asset, id) => {
						return <InView triggerOnce={true} key={id}>
								{({ inView, ref }) => (
									<div
										className={`
											asset-wrapper
											asset-wrapper--${asset.type}
											${inView?' asset-wrapper--inView':''}
											${asset.image.bgColor ? `asset-wrapper--padding`:``}
											${asset.padding ? `asset-wrapper--large-padding`:``}
										`}
										style={{
											backgroundColor: asset.image.bgColor
										}}
										ref={ref}
									>
									{asset.image && asset.image.type !== "video" ?
										<Img 
											className="asset-wrapper__image" 
											fluid={asset.image.src.childImageSharp.fluid} alt={asset.image.src.name}
											fadeIn={true}
											durationFadeIn={1000}
											loading={"eager"}
										/>
										:
										<Video srcs={{
											desktop:asset.image.url,
											mobile:asset.image.urlMobile
										}} className="asset-wrapper__image" />
									}
									</div>
								)}
							</InView>
						})
					}
					</div>
					
					{credit && credit.length > 0 &&
						<div className="credit">
							<InView triggerOnce={true}>
								{({ inView, ref }) => (
										<>
											<BlockReveal tag={"p"} className="credit__title" isVisible={inView} delay={400}>
												Credit
											</BlockReveal>
											{credit.map((c, i) => {
												return <BlockReveal tag={"div"} isVisible={inView} delay={400+i*200} key={c}><p ref={i === 0 && ref}>{c}</p></BlockReveal>
											})}
										</>
										)}
							</InView>

						</div>
					}
				</div>
			</div>
		)
	}
}

export default Content