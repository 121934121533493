import React from "react"
import Footer from "./Footer"
import Arrow from "../modules/Arrow"
import {scrollroot} from "../utils/index"
import "./header.scss"

class Header extends React.Component {
	sectionEls;
	section = [];
	oldScrollTop = 0;
	scrollTop;
	count = 0;

	constructor(props) {
		super(props);

		this.state = {
			labels: [
				"Florence Libbrecht",
				"New York City",
				"7+ years",
				{
					copy: "Contact me",
					link: "mailto:florence.libbrecht@gmail.com",
				},
			],
			miniHeader: false,
			active: false,
		}
	}

	componentDidMount() {
		this.scrollroot = scrollroot();
		this.onScroll = this.onScroll.bind(this);
		this.onResize = this.onResize.bind(this);
		this.sectionEls = document.querySelectorAll(".section");
		this.section = Array.from(this.sectionEls).map(el => (
			{
				el: el,
				year: el.dataset.year,
				client: el.dataset.client,
				color: el.dataset.color,
				bgcolor: el.dataset.bgcolor,
				fromTop: el.getBoundingClientRect().top,
			}
		))
		this.scrollTop = this.scrollroot.scrollTop;
		window.addEventListener("scroll", this.onScroll);
		window.addEventListener("resize", this.onResize);

		this.offsetTop = this.ref.offsetTop;

		this.onScrollTopClick = this.onScrollTopClick.bind(this);

		this.onResize();

		requestAnimationFrame(_=>{
			this.setState({
				active: {},
			});
		});

	}

	componentWillUnmount() {
		window.removeEventListener("scroll", this.onScroll);
		window.removeEventListener("resize", this.onResize);
	}

	onScroll() {
		if(this.oldScrollTop > 200 && !this.state.miniHeader) {
			this.setState({
				miniHeader: true
			});
		} else if(this.oldScrollTop < 200 && this.state.miniHeader) {
			this.setState({
				miniHeader: false
			});
		}

		if(this.count < 30) {
			this.count += 1;
		} else {
			this.count = 0;
		}
		let active = {};
		this.section = this.section.map(s => {

			const fromTop = this.count === 30 ? s.el.getBoundingClientRect().top : s.fromTop + (this.oldScrollTop - this.scrollroot.scrollTop);
			
			if(fromTop <= this.offsetTop+10) {
				active = s;
			}

			return {
				...s,
				...{fromTop: fromTop},
			}
		})

		if(active !== this.state.active) {
			this.setState({
				active: active,
			})
		}

		this.oldScrollTop = this.scrollroot.scrollTop;
	}

	onResize() {
		this.section = this.section.map(s => (
			{
				...s,
				...{fromTop: s.el.getBoundingClientRect().top},
			}
		))

		this.offsetTop = this.ref.offsetTop;

		this.onScroll();
	}

	onScrollTopClick() {
		document.documentElement.style.opacity = 0;
		setTimeout(() => {
			this.scrollroot.scrollTop = 0;
			document.documentElement.style.opacity = 1;
		}, 300);
		this.onScroll();
	}

	render() {
		const {display} = this.props;
		const {active, labels, miniHeader} = this.state;
		return (
			<div className={`header-wrapper${miniHeader?' header-wrapper--mini':''}`}
				style={{
					color: active.color || "#000",
					backgroundColor: active.bgcolor || "#fff",
				}}
			>
				<div className="wrapper" ref={r=>this.ref=r}>
					<div className="header header--desktop">
						<span className={`header__copy link ${display?'header__copy--display':''}`}
							onClick={this.onScrollTopClick}
							onKeyDown={()=>{}}
							role="button"
							tabIndex={0}
						>{labels[0]}</span>
						<p className={`header__copy ${display?'header__copy--display':''}`}>{active.client || labels[1]}</p>
						<p className={`header__copy ${display?'header__copy--display':''}`}>{active.year || labels[2]}</p>
						<p className={`header__copy link link--icon ${display?'header__copy--display':''}`}>
							<a href={labels[3].link}
								style={{
									fill:  active.color || "#000",
								}}
							>
								<Arrow />
								{labels[3].copy}
							</a>
						</p>
					</div>
					<div className="header header--mobile">
						<p className={`header__copy ${display?'header__copy--display':''}`}>{active.year || labels[0]}</p>
						<p className={`header__copy ${display?'header__copy--display':''}`}>{active.client || <a href={labels[3].link}><Arrow />{labels[3].copy}</a>}</p>
					</div>
				</div>
				<Footer />
			</div>
		)
	}
}

export default Header;