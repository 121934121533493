import React from "react"

export default () => <svg width="19px" height="10px" viewBox="0 0 19 10">
    <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd"
        style={{
            fill: "inherit",
        }}
    >
        <g style={{
            fill: "inherit",
        }}>
            <g transform="translate(0.000000, -0.050253)"
                style={{
                    fill: "inherit",
                }}
            >
                <rect id="Rectangle" x="0" y="4.05025253" width="14" height="2"></rect>
                <polygon id="Rectangle-Copy" transform="translate(14.000000, 5.000000) rotate(-315.000000) translate(-14.000000, -5.000000) " points="11 2 17 2 17 8"></polygon>
            </g>
        </g>
    </g>
</svg>
