import React from "react"
import TextReveal from "../modules/TextReveal"
import IntroText from "../modules/IntroText"
import Header from "./Header"
import InView from "react-intersection-observer"
import BlockReveal from "../modules/BlockReveal"
import "./about.scss"

class About extends React.Component {

	constructor() {
		super();

		this.state = {
			display: false,
			delayedDisplay: false,
			delayedDisplayDone: false,
		}

		this.titleScrollFromTop = 0;
		this.titleScrollValue = 0;
		this.oldScroll = 0;
		this.windowHeight = 0;
		this.onScroll = this.onScroll.bind(this)
		this.onResize = this.onResize.bind(this)

	}

	componentDidMount() {

		window.addEventListener('scroll', this.onScroll);
		window.addEventListener('resize', this.onResize);

		setTimeout(() => {
			this.setState({
				delayedDisplay: true,
			})
			
			setTimeout(() => {
				this.setState({
					display: true,
				})
				setTimeout(() => {
					this.setState({
						delayedDisplayDone: true,
					})
				}, 2000)
			}, 2000)
		}, 400)

		this.titleScrollFromTop = this.scrollTitleRef.offsetTop;
		this.windowHeight = window.innerHeight;
	}

	componentWillUnmount() {
		window.removeEventListener('scroll', this.onScroll);
		window.removeEventListener('resize', this.onResize);
	}

	onScroll() {
		const progress = (document.documentElement.scrollTop + this.windowHeight - this.titleScrollFromTop)/this.windowHeight;
		if(this.scrollTitleRef) {
			this.scrollTitleRef.style.transform = `translate3d(calc(50% + ${-progress*this.scrollTitleRef.scrollWidth}px),0,0)`;
		}
	}

	onResize() {
		this.titleScrollFromTop = this.scrollTitleRef.offsetTop;
		this.windowHeight = window.innerHeight;
	}

	render() {
		const { display, delayedDisplay, delayedDisplayDone } = this.state;
		return (
			<div className="about section">
				<Header display={display}/>
				
				<div className="about__content about__content--intro wrapper">
					<div className="about__content--intro-content"
						style={{
							transition: 'border-bottom .3s 2s',
							borderBottom: `solid ${display ? '1px' : '0'} rgba(204, 204, 204, 0.5)`,
						}}
					>
						<h1 className="wrapper__copy">
							<span className="introTextWrapper">
								<IntroText 
									copy={[
										"&zwnj;",
										"Salut", 
										"Hello", 
										"Hi"
									]}
									delay={0}
								/>
							</span>
							<TextReveal tag={"span"} className={`wrapper__copy${!delayedDisplayDone?" wrapper__copy__nolink":""}`} autoPlayDelay={2000}>
								<span className="hi-invisible">Hi</span>, I'm an <span className="light">Art Director</span> living in New York City currently working at <a className={`light hover-link`} href="https://www.squarespace.com/" target="_blank" rel="noopener noreferrer"> Squarespace.</a>
							</TextReveal>
						</h1>
						<p className={`about__content__scroll wrapper__copy ${display?'about__content__scroll--display':''}`}>Scroll down</p>
					</div>
				</div>

				<div className="about__content wrapper">
					<InView triggerOnce={true}>
						{({ inView, ref, entry }) => (
							<div ref={ref} className="about__content__copy">
								<BlockReveal tag={"p"} className="project__desc" isVisible={delayedDisplay && inView} delay="400">
									I’m a multidisciplinary designer based in Brooklyn, with over 7+ years of experience. I believe in minimal design, insightful content, and well crafted experiences based on clients and users needs. 
								</BlockReveal>
								<BlockReveal tag={"p"} className="project__desc" isVisible={delayedDisplay && inView} delay="1000">
									I’ve had the opportunity to design for <a className="light hover-link hover-link--small" href="https://www.firstborn.com/" target="_blank" rel="noopener noreferrer">Firstborn</a>, <a className="light hover-link hover-link--small" href="https://yourmajesty.co/" target="_blank" rel="noopener noreferrer">Your Majesty</a> and <a className="light hover-link hover-link--small" href="https://www.squarespace.com/" target="_blank" rel="noopener noreferrer">Squarespace.</a> Clients I worked for include Supercell, Lancôme, L’Oreal, Kiehl’s, Mountain Dew, Adidas, Fisher Price, ANA, and more.
								</BlockReveal>
							</div>
						)}
					</InView>
				</div>

				<div className="about__selected-work">
					<div className="about__selected-work__copy" ref={r=>this.scrollTitleRef=r}>
						Selected Work
					</div>
				</div>

			</div>
		)
	}
}

export default About