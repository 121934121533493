import React from "react"
import "./introText.scss"

class IntroText extends React.PureComponent {
	constructor(props) {
		super(props)
		this.state ={
			pos: 0,
		};
		this.play = this.play.bind(this);
	}

	componentDidMount() {
		setTimeout(() => {
			this.play();
		}, this.props.delay);
	}

	play() {
		const { copy, transitionDuration = .6, transitionDelay = .2} = this.props;
		let i = 0;
		this.setState({
			pos: 0,
		})
		
		this.interval = setInterval(()=>{
			// if(Math.round(this.state.pos * 100) / 100 === Math.round((100 - 100/copy.length) * 100) / 100) {
			if(i >= copy.length - 1) {
				clearInterval(this.interval)
				return;
			}
			i += 1;
			this.setState(state => ({
				pos: state.pos + 100/copy.length,
			}))
		}, transitionDuration*800+transitionDelay*800)
	}

	render() {
		const { pos } = this.state;
		const { copy, transitionDuration = .6 } = this.props;
		
		return (
		<div className={`introText`}>
			<div className={`introTextContent`}
			style={{
				transform: `translate3d(0, -${pos}%, 0)`,
				transition: `transform ${transitionDuration}s`,
			}}
			>
			{copy.map((line, id) => {
				if(line === "&zwnj;") return <span key={id}>&zwnj;</span>;
				return <span key={id}>{line}</span>;
			})}
			</div>
		</div>
		)
}	
}

export default IntroText;