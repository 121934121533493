import React from "react"

import "./textReveal.scss"

class TextReveal extends React.Component {

	constructor(props) {
		super(props)
		this.state = {
			isVisible: false,
		};

		this.copy = [];
		this.wCount = 0;

		React.Children.forEach(this.props.children, (el, i) => {
			if(typeof el === "string") {
				this.copy.push(this.prepare(el, this.wCount));
				this.wCount += el.split(" ").filter(w=>w!=="").length;
			} else {
				const T = el.type;
				this.copy.push(
					<T key={i} className={el.props.className} {...el.props}>
						{this.prepare(el.props.children, this.wCount)}
					</T>
				)
				
			}
			this.wCount += (el.props ? el.props.children : el).split(" ").filter(w=>w!=="").length;
		})
	}

	componentDidMount() {
		const {autoPlayDelay} = this.props;
		if(autoPlayDelay) {
			setTimeout(()=>{
				this.setState({
					isVisible: true,
				});
			}, autoPlayDelay)
		}
	}

	componentWillReceiveProps(nextProps) {
		const {autoPlayDelay, delay} = this.props;
		if(!autoPlayDelay) {
			setTimeout(() => {
				this.setState({
					isVisible: nextProps.isVisible,
				});
			}, delay)
		}
	}

	shouldComponentUpdate(nextProps) {
		if(!nextProps.isVisible && nextProps.isVisible !== undefined) return false;
		return true;
	}

	prepare(str, n) {
		const words = str.split(" ");

		const { speed } = this.props;

		return words.map((w, i) => {
			if(w === "") return false;
			const offset = n+i;
			return (
				<span key={i} className={"textReveal__word"}>
					<span
						className="textReveal__word-anim"
						style={{
							transitionDelay: `${offset*30}ms`,
							transitionDuration: `${(speed || 0.6)+offset*0.05}s`,
						}}
					>
						{w}
					</span>
				</span>
			)
		});
	}

	render() {
		const { isVisible } = this.state;
		const { tag, className, } = this.props;
		const { copy } = this;
		const Tag = tag;

		return (
			<Tag className={`textReveal ${isVisible && "textReveal--visible"} ${className}`}>
				{copy}
			</Tag>
		)
	}
}

export default TextReveal